$(document).ready(function() {
    $(function() {
        $("a[href*='#']:not([href='#'])").click(function() {
            if (location.hostname == this.hostname && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")) {
                var anchor = $(this.hash);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
                if (anchor.length) {
                    $("html, body").animate( { scrollTop: anchor.offset().top }, 1500);
                }
            }
        });
    });
    var contraste = 'clair';
    $('.container-fluid').each(function(index) {
        var vhwindow = $(window).height();
        var scrollPos = $(this).scrollTop();
        if(scrollPos >= ($(this).offset().top - (vhwindow / 1.5))) {
            var bgcolor = $(this).attr('bgcolor');
            $('body').attr("id",bgcolor);
        }
        if(scrollPos >= $(this).offset().top) {
            if($(this).hasClass('bg_fonce')) {
                contraste = 'fonce';
            } else {
                contraste = 'clair';
            }
        }
    });
    // if(contraste == 'fonce') {
    //     $('.burger').addClass('fonce').removeClass('clair');
    //     $('.etoile').addClass('fonce').removeClass('clair');
    // } else {
    //     $('.burger').addClass('clair').removeClass('fonce');
    //     $('.etoile').addClass('clair').removeClass('fonce');
    // }
    $('.video_home').click(function() {
        $('.video_home').get(0).play();
        $('.menu-marque').removeClass('on');
    });
    $('.burger').click(function() {
        $('.menu').toggleClass('on');
    });
    $('.close_menu').click(function() {
        $('.menu').toggleClass('on');
    });
    $('.btn_close').click(function() {
        $('.menu-marque').removeClass('on');
    });
    $('.tirette').mouseenter(function() {
        $('.menu-marque').addClass('on');
    });
    $('.menu-marque').mouseleave(function() {
        $('.menu-marque').removeClass('on');
    });
    $('.video_home').mouseenter(function() {
        $('.menu-marque').removeClass('on');
    });
    $('.univers').mouseenter(function() {
        $('.menu').addClass('bgunivers');
        $('.menu').removeClass('bgexpertise');
        $('.menu').removeClass('bgcollections');
        $('.menu').removeClass('bgdivers');
    });
    $('.expertise').mouseenter(function() {
        $('.menu').removeClass('bgunivers');
        $('.menu').addClass('bgexpertise');
        $('.menu').removeClass('bgcollections');
        $('.menu').removeClass('bgdivers');
    });
    $('.collections').mouseenter(function() {
        $('.menu').removeClass('bgunivers');
        $('.menu').removeClass('bgexpertise');
        $('.menu').addClass('bgcollections');
        $('.menu').removeClass('bgdivers');
    });
    $('.divers').mouseenter(function() {
        $('.menu').removeClass('bgunivers');
        $('.menu').removeClass('bgexpertise');
        $('.menu').removeClass('bgcollections');
        $('.menu').addClass('bgdivers');
    });
    $(document).scroll(function () {
        scrollBanner();
        var scrollPos = $(this).scrollTop();
        if($('.ligne_2').length != 0) {
            var scrollPosligne_2 = $('.ligne_2').offset().top;
            var scrollPosligne_1 = $('.ligne_1').offset().top;
            var bodyWidth = $( window ).width();
            if(scrollPos >= scrollPosligne_1) {
            //     $('.etoile').css('opacity', '0');
                $('.navbar-brand').css('opacity', '1');
            }
            if(scrollPos >= scrollPosligne_2) {
            //     $('.etoile').css('opacity', '1');
                $('.navbar-brand').css('opacity', '0');
            }
            if(bodyWidth < 768) {
                $('.etoile').css('opacity', '0');
                $('.navbar-brand').css('opacity', '0');    
            }
        }
        var contraste = 'clair';
        $('.container-fluid').each(function(index) {
            var vhwindow = $(window).height();
            if(scrollPos >= ($(this).offset().top - (vhwindow / 1.5))) {
                var bgcolor = $(this).attr('bgcolor');
                $('body').attr("id",bgcolor);
            }
            if(scrollPos >= $(this).offset().top) {
                if($(this).hasClass('bg_fonce')) {
                    contraste = 'fonce';
                } else {
                    contraste = 'clair';
                }
            }
        });
        // if(contraste == 'fonce') {
        //     $('.burger').addClass('fonce').removeClass('clair');
        //     $('.etoile').addClass('fonce').removeClass('clair');
        // } else {
        //     $('.burger').addClass('clair').removeClass('fonce');
        //     $('.etoile').addClass('clair').removeClass('fonce');
        // }
    });
    scrollBanner();
});
function scrollBanner() {
    var scrollPos = $(this).scrollTop();
    var bodyWidth = $( window ).width();
    if(bodyWidth > 768) {
        if($('.parallax1').length != 0) {
            var yPos = 200 - (scrollPos / 10);
            var yPos2 = 200 - (scrollPos / 10);
            if(yPos < 0) { yPos = 0;}
            if(yPos2 < 0) { yPos2 = 0;}
            if($('body').hasClass('home')) {
                $('.parallax1').css({
                    'backgroundPosition': '50% '+ yPos +'%'
                });
            } else {
                $('.parallax1').css({
                    'backgroundPosition': '50% '+ yPos2 +'%'
                });
            }
        }
        if($('.parallax2').length != 0) {
            var yPos = scrollPos / 45;
            var yPos2 = 100 - (scrollPos / 20);
            if(yPos2 < 0) { yPos2 = 0;}
            if($('body').hasClass('home')) {
                $('.parallax2').css({
                    'backgroundPosition': '50% '+ yPos +'%'
                });
            } else {
                $('.parallax2').css({
                    'backgroundPosition': '50% '+ yPos2 +'%'
                });
            }
        }
        if($('.parallax3').length != 0) {
            var yPos = 300 - (scrollPos / 15);
            if(yPos < 0) { yPos = 0;}
            $('.parallax3').css({
                'backgroundPosition': '50% '+ yPos +'%'
            });
        }
        if($('.parallax4').length != 0) {
            var yPos = 300 - (scrollPos / 20);
            if(yPos < 0) { yPos = 0;}
            $('.parallax4').css({
                'backgroundPosition': '50% '+ yPos +'%'
            });
        }
        if($('.parallax5').length != 0) {
            var yPos = 300 - (scrollPos / 20);
            if(yPos < 0) { yPos = 0;}
            $('.parallax5').css({
                'backgroundPosition': '50% '+ yPos +'%'
            });
        }
        if($('.parallax6').length != 0) {
            var yPos = 300 - (scrollPos / 20);
            if(yPos < 0) { yPos = 0;}
            $('.parallax6').css({
                'backgroundPosition': '50% '+ yPos +'%'
            });
        }
    }
}
function SmoothScroll(target, speed, smooth) {
    if (target == document)
	    target = (document.documentElement || document.body.parentNode || document.body)
    var moving = false
    var pos = target.scrollTop
    target.addEventListener('mousewheel', scrolled, false)
    target.addEventListener('DOMMouseScroll', scrolled, false)

    function scrolled(e) {
	    e.preventDefault();
	    var delta = normalizeWheelDelta(e)
	    pos += -delta * speed
	    pos = Math.max(0, Math.min(pos, target.scrollHeight - target.clientHeight))
	    if (!moving) update()
    }

    function normalizeWheelDelta(e){
	    if(e.detail){
		    if(e.wheelDelta)
			    return e.wheelDelta/e.detail/40 * (e.detail>0 ? 1 : -1)
		    else
			    return -e.detail/3
	    }else
		    return e.wheelDelta/500
    }

    function update() {
	    moving = true
	    var delta = (pos - target.scrollTop) / smooth
	    target.scrollTop += delta
	    if (Math.abs(delta) > 0.5)
		    requestFrame(update)
	    else
		    moving = false
    }

    var requestFrame = function() {
	    return (
		    window.requestAnimationFrame ||
		    window.webkitRequestAnimationFrame ||
		    window.mozRequestAnimationFrame ||
		    window.oRequestAnimationFrame ||
		    window.msRequestAnimationFrame ||
		    function(func) {
			    window.setTimeout(func, 1000 / 50);
		    }
	    );
    }()
}

$('.first-word').each(function(){
    var me = $(this);
    me.html(me.html().replace(/^(\w+)/, '<strong class="dore">$1</strong>'));
});